<template>
  <BlogRootContent
    v-once
    :request-state="searchAsync.data.value?.state"
    :found="searchAsync.data.value?.found"
    :items="searchAsync.data.value?.items"
    :page="page"
    :breadcrumbs="breadcrumbs"
  />
</template>

<script setup lang="ts">
import type { PostCard, PostFromResponse } from '@/types/common';

const props = defineProps<{
  page: number;
  fixLocale: string;
}>();

const { t, locale, setLocale } = useI18n();

if (props.fixLocale && locale.value !== props.fixLocale) {
  setLocale(props.fixLocale);
}

const localePath = useLocalePath();

const POSTS_PER_PAGE = 12;

async function fetchItems(): Promise<{
  state?: 'loading' | 'error' | 'success';
  found: number;
  items: PostCard[];
}> {
  try {
    if (process.client) {
      throw new Error('Fetch function should only be called on the server');
    }

    const { useWpClient = null } = process.client
      ? {}
      : await import('#imports');
    const { GET_WP_POSTS = null } = process.client
      ? {}
      : await import('@/utils/queries');

    if (!useWpClient || !GET_WP_POSTS) {
      throw new Error('Missing dependencies');
    }

    const client = useWpClient();

    const gqlResponse = await client.query<{
      posts: {
        nodes: PostFromResponse[];
        pageInfo: {
          offsetPagination: {
            total: number;
          };
        };
      };
    }>({
      query: GET_WP_POSTS,
      variables: {
        perPage: POSTS_PER_PAGE,
        offset: (props.page - 1) * POSTS_PER_PAGE,
        language: locale.value === 'us' ? 'en' : locale.value
      }
    });

    const items = gqlResponse.data.posts.nodes.map(node => {
      const category = node.categories.nodes[0] || {
        slug: 'posts',
        name: 'Posts',
        parent: { node: { slug: 'posts', name: 'Posts' } }
      };

      const parentCategory = category?.parent?.node || { slug: 'posts' };

      const to = localePath(
        `/${t('routes.blog')}` +
          `/${parentCategory?.slug}` +
          `/${category?.slug}` +
          `/${node.slug}`
      );

      const categoryLink = localePath(
        `/${t('routes.blog')}` +
          `/${parentCategory?.slug}` +
          `/${category?.slug}`
      );

      const categoryName = category?.name;

      return {
        title: node.title,
        excerpt: node.excerpt,
        to,
        image: node.featuredImage?.node?.sourceUrl,
        imageAlt: node.featuredImage?.node?.altText,
        category: categoryName,
        categoryLink
      };
    });

    const found =
      gqlResponse.data.posts.pageInfo?.offsetPagination?.total || items.length;

    return {
      state: 'success',
      items,
      found
    };
  } catch (error) {
    console.error(error);
    return {
      state: 'error',
      items: [],
      found: 0
    };
  }
}

const searchAsync = await useAsyncData<{
  state?: 'initializing' | 'loading' | 'error' | 'success';
  found: number;
  items: PostCard[];
}>('root-blog-results', () => Object.freeze(fetchItems()));

useSeoMeta({
  title: t('blog.seo.title'),
  description: t('blog.description')
});

const { link } = useCanonicalLink((t, locale) => {
  const path =
    `/${t('routes.blog')}` +
    (props.page > 1 ? `/${props.page}-${t('paramsKeys.page')}` : '');
  return localePath(path, locale);
});

const host = useHost();

const breadcrumbs = [
  {
    name: t('seo.home'),
    item: host + localePath('/')
  },
  {
    name: t('menu.blog'),
    item: host + localePath(`/${t('routes.blog')}`),
    interactuable: false
  }
];

useSchemaOrg([
  ...useCommonSchemaOrg(link),
  defineBreadcrumb({
    '@id': link + '#breadcrumb',
    itemListElement: breadcrumbs.map(({ name, item }) => ({ name, item }))
  })
]);
</script>
